$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

    $('.checkbox.noscroll').on('change', function() {

        if($(this).is(':checked')) 
            $('html').addClass('noscroll')
        else 
            $('html').removeClass('noscroll')
    });

    $('.checkbox').on('change', function() {
        $('.checkbox').not(this).prop('checked', false);  
    });  

    $(".scrollTo").click(function(event) {
        event.preventDefault();
        $('html,body').animate( { scrollTop:$(".content").offset().top - 100} , 1000);
    });

    $('.slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        lazyLoad: 'progressive',
        arrows: false,
        autoplay: true,
        speed: 2000,
        dots: false,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        centerPadding: '0',
      responsive: [
      {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4
          }
        },
      {
          breakpoint: 799,
          settings: {
            slidesToShow: 3
          }
        },
      {
          breakpoint: 479,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1,
          settings: {
            slidesToShow: 1,
            centerMode: false
          }
        }
        
      ]
    });
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/

$(document).scroll(function(){
    var userScroll = $(document).scrollTop();
    if( userScroll >= 33 )
    {
        $('.fixed-navi').addClass('small');
    }
    else
    {
        $('.fixed-navi').removeClass('small');
    }
});